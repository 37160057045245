// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-beer-js": () => import("./../src/pages/beer.js" /* webpackChunkName: "component---src-pages-beer-js" */),
  "component---src-pages-beerfinder-js": () => import("./../src/pages/beerfinder.js" /* webpackChunkName: "component---src-pages-beerfinder-js" */),
  "component---src-pages-brewery-js": () => import("./../src/pages/brewery.js" /* webpackChunkName: "component---src-pages-brewery-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-happenings-js": () => import("./../src/pages/happenings.js" /* webpackChunkName: "component---src-pages-happenings-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reclamation-room-js": () => import("./../src/pages/reclamation-room.js" /* webpackChunkName: "component---src-pages-reclamation-room-js" */),
  "component---src-pages-taproom-js": () => import("./../src/pages/taproom.js" /* webpackChunkName: "component---src-pages-taproom-js" */)
}

